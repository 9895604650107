import $ from "jquery";
import {
    ApiError,
    AsyncTaskStatus,
    BaseResponse,
    BaseResponseObject,
    BatchesTypes,
    BeginReattach,
    CheckUpload, Constants,
    Correspondent,
    EmptyResponse,
    ExportedPayments,
    ExportedPaymentsWrapper,
    ExportPaymentDetails,
    FailedPrintBatches,
    FinalReport,
    FullReport,
    GenerateCredentialBatches,
    GenericPayload,
    getDefaultRequestOptions,
    InputGetUpdates,
    LoadData,
    ManageBatch,
    Nation,
    PrintableBatch,
    PrintableCustomer,
    PrintBatchWrapper,
    PrintError,
    RequestOptions,
    SearchPayments,
    Update,
    UploadProgress,
    URL
} from "./Types";
import {Popup} from "../Popups/Popup";

export interface ErrorWrappedDeferred<TR, TJ = any, TN = any> extends JQuery.Deferred<TR, TJ, TN> {
    ew_done(doneCallback: JQuery.TypeOrArray<JQuery.Deferred.Callback<TR>>,
            ...doneCallbacks: Array<JQuery.TypeOrArray<JQuery.Deferred.Callback<TR>>>): this;
}

declare const SMARTPRISE_BACKEND_URL: string;
declare const SMARTPRISE_BACKEND_REFRESH_URL: string;

export class SmartpriseApiClient {
    public static BASE_ENDPOINT: string;
    public static RENEW_ENDPOINT: string;
    public static LOGIN: string;

    private static instance: SmartpriseApiClient;


    public static readonly ErrorMap: Record<number, string> = {
        "-7": "Mail non valida",
        // 20: "La password deve contenere tra gli 8 e i 32 caratteri, almeno una lettera maiuscola, una minuscola e un carattere tra [#?!@$%^&*-]",
        20: "Credenziali non valide",
        21: "Password troppo lunga",
        22: "Password troppo corta",
        41: "Invio email fallito"
    }

    private constructor() {
        SmartpriseApiClient.BASE_ENDPOINT = SMARTPRISE_BACKEND_URL;
        SmartpriseApiClient.RENEW_ENDPOINT = SMARTPRISE_BACKEND_REFRESH_URL;

        SmartpriseApiClient.LOGIN = window.location.origin + window.location.pathname + "?page=login";
    }

    public static getInstance(): SmartpriseApiClient {
        if (!SmartpriseApiClient.instance)
            SmartpriseApiClient.instance = new SmartpriseApiClient();

        return SmartpriseApiClient.instance;
    }

    private _EndApiCall<ST extends BaseResponseObject | BaseResponseObject[], FT extends ApiError>
    (request_options: RequestOptions,
     _deferred: JQuery.Deferred<ST, undefined | string | BaseResponse<ST, FT>>
    ): ErrorWrappedDeferred<ST, undefined | string | BaseResponse<ST, FT>> {

        type response_t = BaseResponse<ST, FT>;
        const deferred = _deferred as ErrorWrappedDeferred<ST, undefined | string | response_t>;

        if (request_options.default_eh)
            deferred.fail(ctx => {
                let err_code: number = void 0;
                if (typeof ctx !== "string") {
                    if (ctx === void 0)
                        ctx = "Errore sconosciuto"
                    else {
                        err_code = ctx.error.code;
                        ctx = ctx.error.code in SmartpriseApiClient.ErrorMap ? SmartpriseApiClient.ErrorMap[ctx.error.code] : ctx.error.message;
                    }
                }

                Popup.DefaultErrorInject(<string>ctx).popup.SetShowCloseCross();

                if (err_code >= 10 && err_code < 20) {
                    setTimeout(() => {
                        window.location.assign(SmartpriseApiClient.LOGIN);
                    }, 7_500)
                }
            });

        deferred.ew_done = (doneCallback: JQuery.Deferred.Callback<ST>,
                            ...doneCallbacks: JQuery.Deferred.Callback<ST>[]) => {
            const wp = (f: JQuery.Deferred.Callback<ST>) => {

                return (...args: any[]) => {
                    try {
                        f(...args);
                    } catch (e) {
                        console.error(e);
                        console.log(f)
                    }
                }
            }
            deferred.done(wp(doneCallback), ...doneCallbacks.map(wp));
            return deferred;
        }

        if (request_options.show_loading) {
            setTimeout(() => {
                const control = $(`<div class="loading-wp"><div><img src="${request_options.loading_image}" alt=""></div></div>`);
                control.prependTo(document.body);
                deferred.always(() => control.remove())
            }, 1000);
        }

        return deferred;
    }

    private _ProcessApiCallResponse<ST extends BaseResponseObject | BaseResponseObject[] = EmptyResponse, FT extends ApiError = ApiError>
    (params: GenericPayload,
     method: string,
     resolver: (content: object) => ST,
     request: () => JQuery.jqXHR<BaseResponse<ST, FT>>,
     request_options: RequestOptions,
     wraps: JQuery.Deferred<ST, string | undefined | BaseResponse<ST, FT>> = null,
    ): JQuery.Deferred<ST, string | undefined | BaseResponse<ST, FT>> {
        type d_type = string | BaseResponse<ST, FT>;
        const deferred = wraps ?? $.Deferred<ST, d_type>();

        deferred.fail(e => (e))

        request().then(
            (data: BaseResponse<ST, FT>) => {
                if (!data.success) {
                    deferred.reject(data);
                    return;
                }

                try {
                    deferred.resolve(resolver(data.result));
                } catch (e) {
                    deferred.reject(`Invalid response from ${method}`);
                    console.error(e);
                }
            }, e => {
                const js = e.responseJSON as BaseResponse<ST, FT> | undefined;

                if (e.status == 401 && request_options.fail_handle_session_handle && js !== void 0) {
                    if (js.error.code === 12) { // ACCESS_TOKEN_EXPIRED
                        this._RenewToken()
                            .done(() => this._ProcessApiCallResponse(params, method, resolver, request, request_options, wraps))
                            .fail(() => document.location = SmartpriseApiClient.LOGIN);
                        return;
                    } else if (js.error.code === 13 || js.error.code === 14 || js.error.code === 15) { // REFRESH_TOKEN_EXPIRED|USER_DISABLED|USER_INVALID_CREDENTIALS
                        document.location = SmartpriseApiClient.LOGIN
                        return;
                    }
                }

                deferred.reject(js);
            });

        return deferred;
    }

    private _RenewToken(): JQuery.jqXHR {
        return $.get(SmartpriseApiClient.RENEW_ENDPOINT, {is_rest: true});
    }

    private _ApiCall<ST extends BaseResponseObject | BaseResponseObject[] = EmptyResponse>(data: GenericPayload,
                                                                                           resolver: (content: object) => ST,
                                                                                           method: string,
                                                                                           request_options: RequestOptions) {
        const url = SmartpriseApiClient.BASE_ENDPOINT + "?method=" + method;
        const payload = {
            url: url,
            type: "POST",
            data: JSON.stringify(data),
            contentType: "application/json; charset=utf-8",
            dataType: "json"
        };

        const request = () => $.ajax(payload);

        request_options ??= getDefaultRequestOptions();
        const deferred = this._ProcessApiCallResponse(data, method, resolver, request, request_options);

        return this._EndApiCall(request_options, deferred)
    }

    private ApiCall<ST extends BaseResponseObject = EmptyResponse>(type: { new(v: object): ST },
                                                                   params: GenericPayload,
                                                                   method: string,
                                                                   request_options: RequestOptions) {
        return this._ApiCall(params,
            (content: object) => new type(content),
            method,
            request_options);
    }

    private ArrayApiCall<ST extends BaseResponseObject = EmptyResponse>(type: { new(v: object): ST },
                                                                        params: GenericPayload,
                                                                        method: string,
                                                                        request_options: RequestOptions) {
        return this._ApiCall(params,
            (content: object[]): ST[] => content.map(x => new type(x)),
            method,
            request_options);
    }

    /** AUTH */

    public Login(email: string,
                 password: string,
                 request_options?: RequestOptions) {
        return this.ApiCall(EmptyResponse, {email: email, password: password}, "rest\\auth\\login", request_options)
    }

    public log_out(request_options?: RequestOptions) {
        return this.ApiCall(EmptyResponse, {}, "rest\\auth\\logout", request_options)
    }

    /** END AUTH */

    /** SYNC */

    public reset_all(
        request_options?: RequestOptions
    ) {
        return this.ApiCall(EmptyResponse, {}, "rest\\sync\\reset_all", request_options)
    }

    public get_errors(
        request_options?: RequestOptions
    ) {
        return this.ArrayApiCall(PrintError, {}, "rest\\sync\\get_errors", request_options)
    }

    public begin_reattach(
        request_options?: RequestOptions
    ) {
        return this.ApiCall(BeginReattach, {}, "rest\\sync\\begin_reattach", request_options)
    }

    public get_updates(updates?: InputGetUpdates,
                       filter?: number[],
                       request_options?: RequestOptions) {
        return this.ArrayApiCall(Update, {updates: updates, filter: filter}, "rest\\sync\\get_updates", request_options)
    }

    public get_async_task_status(request_options?: RequestOptions) {
        return this.ApiCall(AsyncTaskStatus, {}, "rest\\sync\\get_async_task_status", request_options)
    }

    /** END SYNC */

    /** MANAGE BATCHES */
    public get_batches(items_per_page: number, page?: number, request_options?: RequestOptions) {
        page ??= 0;
        return this.ApiCall(ManageBatch, {
            items_per_page: items_per_page,
            page: page
        }, "rest\\manage_batches\\get_batches", request_options)
    }

    public drop_batch(drop_id: number | string, request_options?: RequestOptions) {
        return this.ApiCall(EmptyResponse, {drop_id: drop_id}, "rest\\manage_batches\\drop_batch", request_options)
    }

    /** END MANAGE BATCHES */

    /** PRINT LETTERS */
    public begin_unique_keys(selected: GenerateCredentialBatches,
                             selected_batch_ids: number[],
                             request_options?: RequestOptions) {
        return this.ArrayApiCall(Update, {
            selected_items: selected,
            selected_batch_ids: selected_batch_ids
        }, "rest\\print_letters\\begin_unique_keys", request_options)
    }

    public get_printable_batches(request_options?: RequestOptions) {
        return this.ArrayApiCall(PrintableBatch, {}, "rest\\print_letters\\get_printable_batches", request_options)
    }

    public generate_unique_keys(request_options?: RequestOptions) {
        if (request_options === void 0) {
            request_options = getDefaultRequestOptions()
            request_options.show_loading = false;
            request_options.default_eh = false;
        }
        return this.ApiCall(EmptyResponse, {}, "rest\\print_letters\\generate_unique_keys", request_options)
    }

    public begin_print_pdf(request_options?: RequestOptions) {
        return this.ArrayApiCall(Update, {}, "rest\\print_letters\\begin_print_pdf", request_options);
    }

    public print_pdf(request_options?: RequestOptions) {
        if (request_options === void 0) {
            request_options = getDefaultRequestOptions()
            request_options.show_loading = false;
            request_options.default_eh = false;
        }
        return this.ApiCall(EmptyResponse, {}, "rest\\print_letters\\print_pdf", request_options)
    }

    public get_report(request_options?: RequestOptions) {
        return this.ApiCall(FullReport, {}, "rest\\print_letters\\get_report", request_options)
    }

    public download_zip(request_options?: RequestOptions) {
        return this.ApiCall(URL, {}, "rest\\print_letters\\download_zip", request_options)
    }

    public close_batch(date: string, request_options?: RequestOptions) {
        return this.ApiCall(EmptyResponse, {name: date}, "rest\\print_letters\\close_batch", request_options);
    }

    /** LOAD DATA */
    public upload_manual_payments(
        csv: string,
        name: string,
        month: number,
        year: number,
        request_options?: RequestOptions
    ) {
        return this.ApiCall(LoadData, {
            csv: csv,
            name: name,
            month: month,
            year: year,
            allow_cache: true,
        }, "rest\\load_data\\upload_manual_payments", request_options)
    }

    /** END PRINT LETTERS*/

    public get_upload_progress(request_options?: RequestOptions) {
        return this.ApiCall(UploadProgress, {}, "rest\\load_data\\get_upload_progress", request_options)
    }

    public check_upload(request_options?: RequestOptions) {
        return this.ApiCall(CheckUpload, {}, "rest\\load_data\\check_upload", request_options)
    }

    public upload_batch(
        csv: string,
        name: string,
        batch_type: BatchesTypes,
        request_options?: RequestOptions
    ) {
        return this.ApiCall(LoadData, {
            batch_type: batch_type,
            name: name,
            csv: csv,
            allow_cache: true,
        }, "rest\\load_data\\upload", request_options)
    }

    public get_printable_customers(batch_ids: number[], request_options?: RequestOptions) {
        return this.ArrayApiCall(PrintableCustomer, {batch_ids: batch_ids,}, "rest\\print_letters\\get_printable_customers", request_options)
    }

    public redo(request_options?: RequestOptions) {
        return this.ApiCall(FailedPrintBatches, {}, "rest\\print_letters\\redo", request_options)
    }

    public get_failed_batches(request_options?: RequestOptions) {
        return this.ApiCall(FailedPrintBatches, {}, "rest\\print_letters\\get_failed_batches", request_options)
    }

    public get_print_batches(items_per_page: number, page?: number, request_options?: RequestOptions) {
        return this.ApiCall(PrintBatchWrapper, {
            items_per_page: items_per_page,
            page: page
        }, "rest\\batch_processing\\get_print_batches", request_options)
    }

    public update_print_batch_name(batch_id: number, name: string, request_options?: RequestOptions) {
        return this.ApiCall(EmptyResponse, {
            batch_id: batch_id,
            name: name
        }, "rest\\batch_processing\\update_print_batch_name", request_options)
    }

    public get_final_report(batch_id?: number, request_options?: RequestOptions) {
        return this.ApiCall(FinalReport, {batch_id: batch_id}, "rest\\batch_processing\\get_final_report", request_options)
    }

    public begin_shipping_slip(request_options?: RequestOptions) {
        return this.ArrayApiCall(Update, {}, "rest\\batch_processing\\begin_shipping_slip", request_options)
    }

    public create_shipping_slip(request_options?: RequestOptions) {
        if (request_options === void 0) {
            request_options = getDefaultRequestOptions()
            request_options.show_loading = false;
            request_options.default_eh = false;
        }
        return this.ArrayApiCall(EmptyResponse, {}, "rest\\batch_processing\\create_shipping_slip", request_options)
    }

    public begin_send_to_printer(request_options?: RequestOptions) {
        return this.ArrayApiCall(Update, {}, "rest\\batch_processing\\begin_send_to_printer", request_options)
    }

    public send_to_printer(request_options?: RequestOptions) {
        if (request_options === void 0) {
            request_options = getDefaultRequestOptions()
            request_options.show_loading = false;
            request_options.default_eh = false;
        }
        return this.ArrayApiCall(EmptyResponse, {}, "rest\\batch_processing\\send_to_printer", request_options)
    }

    public close_process(request_options?: RequestOptions) {
        return this.ApiCall(FinalReport, {}, "rest\\batch_processing\\close_process", request_options)
    }

    public get_payment_exports(items_per_page: number, page?: number, request_options?: RequestOptions) {
        page ??= 0;
        return this.ApiCall(ExportedPayments, {
            items_per_page: items_per_page,
            page: page
        }, "rest\\payments_exports\\get_payment_exports", request_options)
    }

    public get_mail_reports(items_per_page: number, page?: number, request_options?: RequestOptions) {
        return this.ApiCall(ExportedPaymentsWrapper, {
            items_per_page: items_per_page,
            page: page
        }, "rest\\sent_mail_reports\\get_mail_reports", request_options)
    }

    public search_payments(from: Date, to: Date,
                           request_options?: RequestOptions) {
        return this.ApiCall(SearchPayments, {
            from: from,
            to: to
        }, "rest\\payments_exports\\search_payments", request_options);
    }

    public generate_flow(from: Date, to: Date, is_definitive: boolean, name?: string, request_options?: RequestOptions) {
        return this.ApiCall(URL, {
            from: from,
            to: to,
            is_definitive: is_definitive,
            name: name
        }, "rest\\payments_exports\\generate_flow", request_options)
    }

    public get_unmatched_payments(export_id: number, request_options?: RequestOptions) {
        return this.ApiCall(ExportPaymentDetails, {
            export_id: export_id
        }, "rest\\payments_exports\\get_unmatched_payments", request_options);
    }

    public update_export_name(export_id: number, name: string, request_options?: RequestOptions) {
        return this.ApiCall(EmptyResponse, {
            export_id: export_id,
            name: name
        }, "rest\\payments_exports\\update_export_name", request_options);
    }

    public get_correspondents(items_per_page: number, page?: number, request_options?: RequestOptions) {
        page ??= 0;
        return this.ApiCall(Correspondent, {
            items_per_page: items_per_page,
            page: page
        }, "rest\\manage_correspondents\\get_correspondents", request_options)
    }

    public get_nation_mapping(request_options?: RequestOptions) {
        return this.ArrayApiCall(Nation, {},
            "rest\\manage_correspondents\\get_nation_mapping", request_options)
    }

    public create_correspondent(name: string, email: string, nation_id: number, enabled: boolean, notes: string, request_options?: RequestOptions) {
        return this.ApiCall(EmptyResponse, {
                name: name,
                email: email,
                notes: notes,
                enabled: enabled,
                nation_id: nation_id,
            },
            "rest\\manage_correspondents\\create_correspondent", request_options)
    }

    public update_correspondent(correspondent_id: number, name: string, email: string, nation_id: number, enabled: number | boolean, notes: string, request_options?: RequestOptions) {
        enabled = enabled ? 1 : 0
        return this.ApiCall(EmptyResponse, {
                correspondent_id: correspondent_id,
                name: name,
                email: email,
                notes: notes,
                enabled: enabled,
                nation_id: nation_id,
            },
            "rest\\manage_correspondents\\update_correspondent", request_options)
    }

    public get_constants(request_options?: RequestOptions) {
        return this.ApiCall(Constants, {}, "rest\\manage_constants\\get_constants", request_options)
    }

    public set_constants(bank_name: string,
                         owner: string,
                         bic: string,
                         iban: string,
                         enable_online_payments: boolean,
                         request_options?: RequestOptions) {
        return this.ApiCall(Constants, {
            bank_name,
            owner,
            bic,
            iban,
            enable_online_payments
        }, "rest\\manage_constants\\set_constants", request_options)
    }
}
