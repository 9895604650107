import $ from "jquery";
import {BaseHandler} from "./BaseHandler";
import {AsyncTaskStatus, BeginReattach, getDefaultRequestOptions, ProcessedUpdate, Update} from "../Api/Types";

export class UpdateLoopOptions {
    public step: number;
    public on_completed: (all_ok: boolean) => void = () => {
    };
    public formatter: (processed_no: number, total_no: number) => string;
    public init: Update[] = void 0;
    public build_only: boolean = false
    public merged_phase: boolean = false

    public constructor(step: number, extra: Partial<UpdateLoopOptions> = {}, defaults: Partial<UpdateLoopOptions> = {}) {
        this.step = step;
        this.formatter = (processed_no: number, total_no: number) => `${processed_no}/${total_no}`;

        Object.assign(this, defaults);
        Object.assign(this, extra);
    }
}

type remapped_update = { -readonly [k in keyof Update]: Update[k] }
type progress_t = {
    progress_bar: JQuery,
    updates: { [print_id: number]: remapped_update },
    progress_map: { [print_id: number]: JQuery },
    errors: number[]
}
type total_t = { [key: string | typeof UpdaterBaseHandler.aggregate_key]: progress_t }
type processed_t = { [print_id: number]: ProcessedUpdate };
const background_options = getDefaultRequestOptions()
background_options.show_loading = false;

export type UpdaterContainers = {
    progress_container: JQuery,
    nation_container?: JQuery
}

export abstract class UpdaterBaseHandler extends BaseHandler {
    public static readonly aggregate_key = "aggregate_mask";

    private running: boolean = false;
    private running_check: number = 0;
    private all_ok: boolean = true;
    private reorder_callback: () => void = () => {
    };

    public handle(): void {
        super.handle();
    }

    private update_interval: ReturnType<typeof setInterval> = null;

    private update_progress(total: total_t, options: UpdateLoopOptions) {
        const error_c: { [nation_id: number]: number } = {};
        const total_c: { [nation_id: number]: number } = {};
        const aggregate_out = total[UpdaterBaseHandler.aggregate_key];
        aggregate_out.updates = {};

        for (const update_set_key of Object.keys(total)) {
            if (update_set_key === UpdaterBaseHandler.aggregate_key)
                continue;

            const update_set = total[update_set_key];

            for (const update of Object.values(update_set.updates)) {
                let fake_update = aggregate_out.updates[update.nation_id];
                if (fake_update === void 0) {
                    fake_update = aggregate_out.updates[update.nation_id] = JSON.parse(JSON.stringify(update));
                    fake_update.customer_name = "fake-customer-name";
                } else {
                    fake_update.total_no += update.total_no;
                    fake_update.processed_no += update.processed_no;
                }

                aggregate_out.progress_map[fake_update.nation_id].attr("data-completed", update.processed_no === update.total_no ? "true" : "false");

                update.nation_id in total_c ? ++total_c[update.nation_id] : (total_c[update.nation_id] = 0);

                if (update_set.errors.includes(update.print_id))
                    update.nation_id in error_c ? ++error_c[update.nation_id] : (error_c[update.nation_id] = 0);
            }
        }

        let full_tot = Object.keys(error_c).length === Object.keys(total_c).length;
        for (const error_nation_id in error_c) {
            const tot = error_c[error_nation_id] === total_c[error_nation_id];
            full_tot &&= tot;
            aggregate_out.progress_map[error_nation_id].find(".progress").addClass(tot ? "error" : "faulty");
            aggregate_out.progress_map[error_nation_id].attr("data-completed", "false");
        }

        if (Object.keys(error_c).length)
            aggregate_out.progress_bar.find(".progress").addClass(full_tot ? "error" : "faulty");

        const animate = (e: JQuery) => {
            e.css("width", "0%")
                .animate({"width": "100%", "margin-left": "100%"}, 600, () => {
                    e.css({"margin-left": "0%", "width": "0%"});
                });
        }

        for (const update_set_key of Object.keys(total)) {
            const update_set = total[update_set_key];
            const is_agg = update_set_key === UpdaterBaseHandler.aggregate_key;
            const [tot, comp] = Object.values(update_set.updates).reduce(
                (s, u) => [s[0] + u.total_no, s[1] + u.processed_no], [0, 0]
            );

            const percentage = comp / tot * 100;
            update_set.progress_bar.find('[data-content-type="status"]').text(options.formatter(comp, tot));

            const tmp = update_set.progress_bar.find(".progress");
            if (percentage >= 0) {
                tmp.css("width", `${percentage}%`)
                update_set.progress_bar.find('[data-content-type="progress"]').text(`${percentage.toFixed(2)}%`);
            } else {
                animate(tmp);
                update_set.progress_bar.find('[data-content-type="progress"]').text("");
            }

            if (!is_agg && update_set.errors.length !== 0)
                tmp.addClass(update_set.errors.length === Object.keys(update_set.updates).length ? "error" : "faulty");

            for (const update of Object.values(update_set.updates)) {
                const progress_bar = update_set.progress_map[is_agg ? update.nation_id : update.print_id];
                const percentage = update.processed_no / update.total_no * 100;
                const fault = !is_agg && update_set.errors.includes(update.print_id);

                progress_bar.find('[data-content-type="status"]').text(options.formatter(update.processed_no, update.total_no));

                const tmp = progress_bar.find(".progress");

                if (percentage >= 0) {
                    tmp.css("width", `${percentage}%`);
                    progress_bar.find('[data-content-type="progress"]').text(`${percentage.toFixed(2)}%`);
                } else {
                    animate(tmp);
                    progress_bar.find('[data-content-type="progress"]').text("");
                }

                if (!is_agg)
                    progress_bar.attr("data-completed", update.processed_no === update.total_no && !fault ? "true" : "false");

                if (fault)
                    tmp.addClass("error");
            }

            this.reorder_callback();
        }
    }

    protected updateLoop(options: UpdateLoopOptions) {
        if (this.update_interval !== null) {
            window.location.reload();
            return;
        }


        this.running = false;
        this.running_check = 0;
        this.all_ok = true;
        this.reorder_callback = () => {
        };

        const total: total_t = {}
        const containers = this.get_containers(options.step)
        containers.progress_container.empty();
        containers.nation_container?.empty();


        const expand = (key: keyof total_t) => {
            const bar_dom = $(`[data-key="${key}"]`);
            bar_dom.parent().children().removeClass("selected");
            bar_dom.addClass("selected");

            this.reorder_callback = () => {
                if (containers.nation_container === undefined)
                    return;

                containers.nation_container?.empty();
                containers.nation_container?.append(...Object.keys(total[key].progress_map).map(Number).sort(
                    (a, b) => {
                        const v1 = (total[key].updates[a].processed_no / total[key].updates[a].total_no);
                        const v2 = (total[key].updates[b].processed_no / total[key].updates[b].total_no);
                        const n1 = total[key].updates[a].nation_name;
                        const n2 = total[key].updates[b].nation_name;
                        return v2 - v1 || n1.localeCompare(n2);
                    }
                ).map(k => total[key].progress_map[k]))
            }
            this.reorder_callback();
        }

        const updates_src = options.init !== undefined ? $.Deferred().resolve(options.init).done : this.client.get_updates().ew_done;
        updates_src((updates: Update[]) => {
            if (options.merged_phase)
                updates = [updates[0]]
            const agg_progress = this.get_template_jquery("agg-progress-container");
            agg_progress.attr("data-key", UpdaterBaseHandler.aggregate_key);
            total[UpdaterBaseHandler.aggregate_key] = {
                progress_bar: agg_progress,
                updates: {},
                errors: [],
                progress_map: []
            }
            agg_progress.on("click", () => expand(UpdaterBaseHandler.aggregate_key));

            containers.progress_container.append(agg_progress);

            for (const update of updates) {
                const key = update.customer_name;

                if (!(key in total)) {
                    const progress = this.get_template_jquery("progress-container");
                    progress.find('[data-content-type="name"]').text(update.customer_name);
                    progress.attr("data-key", key);

                    progress.on("click", () => expand(key));
                    containers.progress_container.append(progress);

                    if (options.merged_phase)
                        progress.hide();

                    total[key] = {
                        progress_bar: progress,
                        updates: {},
                        errors: [],
                        progress_map: []
                    }
                }

                if (!(update.nation_id in total[UpdaterBaseHandler.aggregate_key].progress_map)) {
                    total[UpdaterBaseHandler.aggregate_key].progress_map[update.nation_id] = this.get_template_jquery("progress-container");
                    total[UpdaterBaseHandler.aggregate_key].progress_map[update.nation_id].find('[data-content-type="name"]').text(update.nation_name);
                }

                total[key].updates[update.print_id] = update;
                total[key].progress_map[update.print_id] = this.get_template_jquery("progress-container");
                total[key].progress_map[update.print_id].find('[data-content-type="name"]').text(update.nation_name);

                if (update.is_fault()) {
                    this.all_ok = false;
                    total[key].errors.push(update.print_id)
                }
            }

            !options.merged_phase && Object.keys(total).length == 2 ? agg_progress.hide() : agg_progress.show();

            if (updates.length)
                expand(updates[0].customer_name)


            this.update_progress(total, options);
            if (options.build_only) {
                options.on_completed(this.all_ok);
                return;
            }

            let processed: processed_t = {};
            for (const i of updates)
                processed[i.print_id] = `${i.print_id}:${i.processed_no}:${i.progress_state}`;

            this.update_interval = setInterval(() => this.update_tick(processed, total, options), 750);
        })
    }

    private update_tick(processed: processed_t, total: total_t, options: UpdateLoopOptions) {
        if (this.running)
            return;
        this.running = true;

        if (++this.running_check === 5 || !this.check_pending(total)) {
            this.client.get_async_task_status(background_options).ew_done(s => {
                if (!s.running) {
                    clearInterval(this.update_interval);
                    this.update_interval = null;

                    this.client.get_errors().ew_done(
                        e => options.on_completed(e.length === 0)
                    ).always(() => this.running = false)
                } else
                    this.running = false;
            })

            if (this.running_check === 5)
                this.running_check = 0;
            else
                return;
        }

        const filter = options.merged_phase ? [Number(Object.keys(processed)[0])] : undefined;

        this.client.get_updates(Object.values(processed), filter, background_options).ew_done(updates => {
            if (!updates)
                return;

            for (const update of updates) {
                if (!(update.print_id in processed)) {
                    if (options.merged_phase)
                        continue;
                    else
                        window.location.reload();
                }

                processed[update.print_id] = `${update.print_id}:${update.processed_no}:${update.progress_state}`;

                const progress = total[update.customer_name];
                progress.updates[update.print_id] = update;

                if (update.is_fault() && !progress.errors.includes(update.print_id)) {
                    this.all_ok = false;
                    progress.errors.push(update.print_id);
                }
            }

            this.update_progress(total, options);
        }).always(() => this.running = false)
    }

    private check_pending(total: total_t) {
        let c = 0;

        for (const update_set_key of Object.keys(total)) {
            if (update_set_key === UpdaterBaseHandler.aggregate_key)
                continue;

            const update_set = total[update_set_key];
            const effective_total_no = Object.values(update_set.updates).reduce((s, u) => {
                if (update_set.errors.includes(u.print_id))
                    return s + u.processed_no;
                return s + u.total_no
            }, 0)
            const comp = Object.values(update_set.updates).reduce((s, u) => s + u.processed_no, 0);

            if (effective_total_no !== comp)
                c++;
        }

        return c;
    }

    protected abstract change_step(step: number): void;

    protected abstract patch_next_button(disabled: boolean): JQuery;

    protected abstract get_containers(step: number): UpdaterContainers;


    protected handleCase(step: number, start_call: () => any, reattach?: BeginReattach, default_opt: Partial<UpdateLoopOptions> = {}) {
        this.change_step(step);
        const begin_called = default_opt.init !== undefined;
        const next_button = this.patch_next_button(true);


        const options = new UpdateLoopOptions(step, default_opt, {
            on_completed: all_ok => {
                if (all_ok) {
                    this.patch_next_button(false);
                    return;
                }

                handle_retry();
            },
        })

        const handle_retry = () => {
            const retry = $("#retry-button")
            if (retry.length)
                retry.show().off("click").one("click", e => {
                    e.currentTarget.style.display = "none";
                    start_call();
                    this.updateLoop(options);
                });
            else
                this.patch_next_button(false);
        }

        const handle = (status: AsyncTaskStatus) => {
            if (status.phase_concluded && status.any_error) {
                this.updateLoop(new UpdateLoopOptions(step, default_opt, {build_only: true}));
                handle_retry();
            } else if (status.phase_concluded)
                this.updateLoop(new UpdateLoopOptions(step, default_opt, {
                    on_completed: () => this.patch_next_button(false),
                    build_only: true
                }));
            else if (status.running)
                this.updateLoop(options);
            else if (!status.running && begin_called)
                this.updateLoop(options);
            else if (!status.running) {
                this.updateLoop(new UpdateLoopOptions(step, default_opt, {build_only: true}));
                $("#start-step").show().one("click", e => {
                    start_call();
                    e.currentTarget.style.display = "none";
                    this.updateLoop(options);
                });
            } else
                console.warn("Unhandled case", status)
        }

        if (reattach === undefined)
            this.client.get_async_task_status().ew_done(x => handle(x));
        else
            handle(reattach);

        return next_button;
    }
}
