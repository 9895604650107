import $ from "jquery";
import {BaseHandler} from "./BaseHandler";
import {ExportedPayment, SearchPayments} from "../Api/Types";
import {Popup} from "../Popups/Popup";
import {NavigableTable} from "./NavigableTableHandler";

export class ExportPaymentsHandler extends BaseHandler {
    private navigableTable: NavigableTable<ExportedPayment>

    public buildRow(e: ExportedPayment) {
        const change_name = (print_batch_id: number, row: JQuery) => {
            const title = this.get_template_text("change-batch-name-title-template");
            const actual_name = row.find('[data-content-type="file_name"]').text();
            const x = Popup.DirectInject(this.get_template_jquery("change-batch-name-template"), title, true);
            x.popup.SetShowCloseCross()
            x.popup.SetShowActions()
            const inp = $("#new-batch-name-input");
            inp.val(actual_name);

            const rename = (name: string) => {
                this.client.update_export_name(print_batch_id, name).done(() => {
                    this.navigableTable.drop_current();
                });
                row.find('[data-content-type="file_name"]').text(name);
                x.popup.Remove();
            }

            const on_confirm = (deferred: JQuery.Deferred<any>) => {
                deferred.done(d => {
                    const inp_val = inp.val().toString().trim().replace(/\s+/g, " ");
                    if (inp_val === "")
                        x.popup.ShowError(this.get_template_jquery("change-batch-name-empty-template"));
                    else if (inp_val.length < 12)
                        x.popup.ShowError(this.get_template_jquery("change-batch-name-too-short-template"));
                    else if (inp_val.length > 128)
                        x.popup.ShowError(this.get_template_jquery("change-batch-name-too-long-template"));
                    else
                        rename(inp_val);
                    on_confirm(d);
                });
                deferred.fail(() => x.popup.Remove())
            }
            on_confirm(x.deferred);
        }

        const row = this.get_template_jquery<HTMLTableRowElement>("export-payment-row-template");

        const period = row.find('[data-content-type="period"]')
        period.text(this.format_date_span(period, e.from_date, e.to_date))

        row.find('[data-content-type="export_payment_id"]').text(e.export_payment_id);
        row.find('[data-content-type="export_date"]').text(this.format_date(e.export_date));
        row.find('[data-content-type="full_items"]').text(e.full_items);
        row.find('[data-action="download"]').on("click", () => this.download(e.csv_download_url));
        row.find('[data-content-type="file_name"]').text(e.name)
        row.find('[data-action="change-name"]').on("click", () => change_name(e.export_payment_id, row));

        if (e.is_aligned) row.find('[data-content-type="details"]').hide();
        else row.find('[data-content-type="details"]').on("click", () => this.show_details(e.export_payment_id));

        return row;
    }

    private show_details(export_payment_id: number) {
        this.client.get_unmatched_payments(export_payment_id).ew_done(exportPaymentDetails => {
            $('#export-payment-wrapper').hide();
            const exportDetails = $('#export-details');
            exportDetails.show();

            const manualUL = $('#manual-ul');
            const automaticUL = $('#automatic-ul');
            manualUL.empty();
            automaticUL.empty();
            for (const automatic of exportPaymentDetails.automatic) {
                const listAutomatic = this.get_template_jquery("export-details-automatic");
                listAutomatic.find('[data-content-type="id"]').text(listAutomatic.find('[data-content-type="id"]').text() + automatic.verbal_id);
                listAutomatic.find('[data-content-type="data"]').text(listAutomatic.find('[data-content-type="data"]').text() + this.format_date(automatic.date));
                automaticUL.append(listAutomatic);
            }
            for (const automatic of exportPaymentDetails.manual) {
                const listManuals = this.get_template_jquery("export-details-manual");
                listManuals.find('[data-content-type="id"]').text(listManuals.find('[data-content-type="id"]').text() + automatic.verbal_id);
                listManuals.find('[data-content-type="data"]').text(listManuals.find('[data-content-type="data"]').text() + this.format_date(automatic.date));
                manualUL.append(listManuals);
            }
        })
    }

    private get_payments() {
        this.navigableTable.drop_cache();
        this.navigableTable.update();
    }

    private set_listeners() {

        const backButton = $('#back-to-export');
        const exportPaymentsPage = $('#export-payment-wrapper');
        const detailsPage = $('#export-details');
        const itemCountLabel = $("#item-count-label");
        itemCountLabel.text("");
        const definitiveExportDiv = $("#definitive-export-div");
        const itemCount = $("#item-count");
        const fromDate = $("#from-date");
        const toDate = $("#to-date");
        const generateFlowButton = $("#generate-flow-button");
        const definitiveExport = $("#definitive-export");
        const custmNameDiv = $("#custom-name-div");
        generateFlowButton.hide();
        definitiveExportDiv.hide();
        detailsPage.hide()
        custmNameDiv.hide();

        backButton.on("click", () => {
            detailsPage.hide()
            exportPaymentsPage.show()
        })

        // this.tableWrapperHandler.e.on("check-me", () => {
        //     this.get_payments()
        // });

        toDate.add(fromDate).on('change', () => {
            itemCountLabel.text("");
            generateFlowButton.hide();
            custmNameDiv.hide();
        }).prop("max", new Date().toISOString().split("T")[0]).prop("min", "2017-01-01");


        generateFlowButton.on("click", () => {
            const is_checked: boolean = definitiveExport.prop("checked")
            const from = new Date(fromDate.val().toString());
            const to = new Date(toDate.val().toString());
            const name = $("#custom-name").val().toString().trim() || this.get_def_name();
            if (is_checked) {
                this.client.generate_flow(from, to, true, name).ew_done(() => {
                    this.navigableTable.drop_cache()
                    this.navigableTable.page = this.navigableTable.pages;
                });
            } else {
                this.client.generate_flow(from, to, false, name).ew_done(x => {
                    this.download(x.url)
                });
            }

        })

        // Disable #item-count initially
        itemCount.prop("disabled", true);

        // Function to check if both dates are filled and enable/disable the button
        const checkDates = () => {
            const fromValue = fromDate.val();
            const toValue = toDate.val();

            if (fromValue && toValue) {
                // Convert the date strings into Date objects for proper comparison
                const from: Date = new Date(fromValue.toString());
                const to: Date = new Date(toValue.toString());

                // Check if the "to" date is greater than or equal to the "from" date
                if (from <= to) {
                    itemCount.prop("disabled", false); // Enable button
                } else {
                    itemCount.prop("disabled", true);  // Disable button
                }
            } else {
                itemCount.prop("disabled", true);  // Disable button if either date is missing
            }
        };

        // Attach event listeners to the date fields
        fromDate.on("change", checkDates);
        toDate.on("change", checkDates);

        // Handle click event on #item-count
        itemCount.on("click", () => {
            // Ensure that the value is a string, even if it"s an array or undefined
            const from = new Date(fromDate.val().toString());
            const to = new Date(toDate.val().toString());

            // Pass Date objects to the search function
            this.search(from, to);
        });
    }

    private search(from: Date, to: Date) {
        this.client.search_payments(from, to).ew_done((x: SearchPayments) => {
            const countLabel = $("#item-count-label");
            countLabel.val(x.count + countLabel.text());
            if (x.count != 0) {
                $("#generate-flow-button").show();
                $("#definitive-export-div").show();
                $("#custom-name-div").show();
            }
            if (x.count === 1) countLabel.text("1 item found");
            else countLabel.text(x.count + " items found ");
        });
    }

    public override handle() {
        super.handle();
        const tableWrapper = $('#table-wrapper-export-payments');
        this.navigableTable = new NavigableTable(tableWrapper, e => {
            return this.buildRow(e)
        }, (page_index, items_per_page) => {
            return this.client.get_payment_exports(items_per_page, page_index);
        });

        this.set_listeners();
        this.get_payments();
    }
}