import $ from "jquery";
import {
    BeginReattach,
    EffectiveProgressState,
    FullReport,
    GenerateCredentialBatches,
    PrintableBatch,
    PrintableCustomer,
    ProgressPhase,
    ServerProgressStateType,
    Update,
} from "../Api/Types";
import {UpdaterBaseHandler, UpdaterContainers} from "./UpdaterBaseHandler";

export class PrintLettersHandler extends UpdaterBaseHandler {
    protected state: EffectiveProgressState;

    private reattach() {
        this.client.begin_reattach().ew_done(x => this.draw_page(x))
    }

    protected get_containers(step: number) {
        return {
            progress_container: $(`#batch-container-step-${step}`),
            nation_container: $(`#nation-container-step-${step}`)
        } as UpdaterContainers;
    }

    protected patch_next_button(disabled: boolean) {
        const button = $<HTMLButtonElement>("#nextStep");
        button.prop("disabled", disabled);
        return button;
    }

    protected change_step(no: number): void {
        $(".progress-bar > .step").removeClass("active")[no - 1].classList.add("active");
        $(".step-content").hide()[no - 1].style.display = "block";

        const reset_all = $("#resetAll");
        [3, 4, 5].includes(no) ? reset_all.show() : reset_all.hide();

        const next = $("#nextStep");
        [1, 2, 3, 4].includes(no) ? next.show() : next.hide();

        const prev = $("#prevStep");
        [2].includes(no) ? prev.show() : prev.hide();

        $("#start-step").hide()
    }

    private selectBatches(batches: PrintableBatch[], selected_ids: number[] = []) {
        this.change_step(1);
        const table = $("#print-letters-select-batches-table-body").empty();
        const next_button = this.patch_next_button(selected_ids.length === 0);

        let current_selected_ids = selected_ids.slice()

        const select_all = $<HTMLInputElement>("#selectAll");
        select_all.on("change", e => {
            table.find('input[type="checkbox"]').prop("checked", e.currentTarget.checked);
            this.patch_next_button(batches.length === 0 || !e.currentTarget.checked);

            current_selected_ids = e.currentTarget.checked ? batches.map(i => i.batch_id) : [];
        })
        select_all.prop("checked", batches.map(i => i.batch_id).sort().toString() === selected_ids.sort().toString());

        const on_click = (e: JQuery.ChangeEvent) => {
            const batch_id = Number(e.currentTarget.getAttribute("data-batch-id"));

            if (e.currentTarget.checked)
                current_selected_ids.push(batch_id)
            else
                current_selected_ids.splice(current_selected_ids.indexOf(batch_id), 1);

            select_all.prop("checked", current_selected_ids.length === batches.length);

            this.patch_next_button(current_selected_ids.length === 0);
        }

        for (const batch of batches) {
            const row = this.get_template_jquery("print-letters-row-template");
            const checkbox = row.find("input[type=checkbox]").attr("data-batch-id", batch.batch_id).on("change", on_click)
            if (selected_ids.includes(batch.batch_id))
                checkbox.prop("checked", true);

            row.find('[data-content-type="print-letters-table-row-name"]').text(batch.name);
            row.find('[data-content-type="print-letters-table-row-date"]').text(this.format_date(batch.upload_date));
            row.find('[data-content-type="print-letters-table-row-items"]').text(batch.items);

            table.append(row);
        }

        const z = (x: PrintableCustomer[]) => this.selectNations(batches, current_selected_ids, x);
        next_button.off("click");
        next_button.on("click", () => this.client.get_printable_customers(current_selected_ids).ew_done(x => z(x)))
    }

    private selectNations(cached_batches: PrintableBatch[], selected_batch_ids: number[], customers: PrintableCustomer[]) {
        this.change_step(2);
        const next_button = this.patch_next_button(true);

        const select_all = $<HTMLInputElement>("#select-all-customers-all-nations");
        if (cached_batches === null) {
            $("#resetAll").show();
            $("#prevStep").hide();
        } else
            $("#prevStep").off("click").on("click", () => this.selectBatches(cached_batches, selected_batch_ids));

        const total_total = $('[data-content-type="total-total"]');
        const total_selected = $('[data-content-type="total-selected"]');

        total_total.text(customers.reduce((s, i) => i.nations.reduce((s, n) => s + n.items, s), 0))

        const customers_container = $('#customers-container').empty();
        const nations_container = $('#nations-container').empty();

        let selected_items: { [customer_id: number]: number[] } = {};
        const id_to_customer: { [customer_id: number]: PrintableCustomer } = {}
        for (const customer of customers)
            id_to_customer[customer.customer_id] = customer;

        const check_select_all = () => {
            let r = true;
            let tot = 0;
            for (const customer of Object.values(id_to_customer)) {

                const nations_ids = customer.nations.map(i => i.nation_id).sort().toString();
                const ok = selected_items[customer.customer_id]?.sort().toString() === nations_ids;
                $(`input[data-c-customer-id="${customer.customer_id}"]`).prop("checked", ok);

                const customer_tot = id_to_customer[customer.customer_id].nations.reduce((s, i) => {
                    if (selected_items[customer.customer_id]?.includes(i.nation_id))
                        return s + i.items
                    return s;
                }, 0);
                $(`[data-customer-id="${customer.customer_id}"]`).find('[data-content-type="selected"]').text(customer_tot.toString());

                tot += customer_tot;

                if (!ok) r = false;
            }

            const c = 0 === Object.values(selected_items).reduce((s, i) => s + i.length, 0);
            this.patch_next_button(c);

            total_selected.text(tot);
            select_all.prop("checked", r);
        }

        for (const customer of customers) {
            const customer_id = customer.customer_id;
            const container = this.get_template_jquery("customer-item-template").attr("data-customer-id", customer_id);
            const checkbox = container.find("input");
            checkbox.attr("data-c-customer-id", customer_id);

            checkbox.on("change", e => {
                if (e.currentTarget.checked)
                    selected_items[customer_id] = id_to_customer[customer_id].nations.map(i => i.nation_id);
                else
                    selected_items[customer_id] = [];

                container.trigger("click");
                $(`input[data-customer-id="${customer_id}"]`).prop("checked", e.currentTarget.checked);

                check_select_all();
            })

            container.find('[data-content-type="total"]').text(id_to_customer[customer_id].items.toString());
            container.find('[data-content-type="name"]').text(id_to_customer[customer_id].customer_name.toUpperCase());

            customers_container.append(container);
        }

        customers_container.off("click", "div.customer-item").on("click", "div.customer-item", e => {
            $("#customers-container").children().removeClass("selected");
            e.currentTarget.classList.add("selected");
            const customer_id = Number(e.currentTarget.getAttribute("data-customer-id"));
            const customer = id_to_customer[customer_id];
            nations_container.empty();

            const nations = customer.nations.sort((a, b) => a.nation.localeCompare(b.nation));
            for (const nation of nations) {
                const row = this.get_template_jquery("nation-item-template");
                const check_box = row.find("input");
                check_box.attr("data-customer-id", customer_id)
                const key = nation.nation_id;

                check_box.prop("checked", selected_items[customer_id]?.includes(key));

                check_box.on("change", e => {
                    if (selected_items[customer_id] === undefined)
                        selected_items[customer_id] = [];

                    if (e.currentTarget.checked)
                        selected_items[customer_id].push(key);
                    else
                        selected_items[customer_id].splice(selected_items[customer_id].indexOf(key), 1);

                    check_select_all();
                })

                row.find('[data-content-type="items"]').text(nation.items);
                row.find('[data-content-type="nation"]').text(nation.nation);

                nations_container.append(row);
            }
        })
        customers_container.find("div.customer-item").first().trigger("click");

        select_all.on("change", e => {
            selected_items = {};
            if (e.currentTarget.checked)
                for (const customer of customers)
                    selected_items[customer.customer_id] = customer.nations.map(i => i.nation_id);

            $('#step2 input[type="checkbox"]').prop("checked", e.currentTarget.checked);
            check_select_all();
        }).prop("checked", true).trigger("change");

        next_button.off("click").on("click", () => {
            const request: GenerateCredentialBatches = [];
            for (const customer_id in selected_items) {
                if (!selected_items[customer_id].length)
                    continue;

                request.push({
                    customer_id: Number(customer_id),
                    nation_ids: selected_items[customer_id]
                });
            }

            this.client.begin_unique_keys(request, selected_batch_ids).ew_done(
                all_updates => {
                    this.client.generate_unique_keys()
                    this.generateCredentials(undefined, all_updates);
                }
            )
        })
    }


    private generateCredentials(reattach?: BeginReattach, updates?: Update[]) {
        const next_button = this.handleCase(3,
            () => this.client.generate_unique_keys(),
            reattach,
            {init: updates});

        next_button.off("click").on("click", () => {
            this.client.begin_print_pdf().ew_done(
                all_updates => {
                    this.client.print_pdf();
                    this.generatePDF(undefined, all_updates);
                }
            )
        })

    }

    private generatePDF(reattach?: BeginReattach, updates?: Update[]) {
        const next_button = this.handleCase(4,
            () => this.client.print_pdf(),
            reattach,
            {init: updates});

        next_button.off("click").on("click", () => this.client.get_report().ew_done(x => this.report(x)));
    }

    private report(report: FullReport) {
        this.change_step(5);
        const container = $('#print-letters-results-container');
        const error_container = $('#print-letters-errors-container').empty();

        const x = {
            style: "currency",
            currency: "EUR",
        }
        //@ts-ignore
        const amount = new Intl.NumberFormat(document.documentElement.lang, x).format(Number(report.total_import.toFixed(2)));
        container.find('[data-content-type="print-letters-results-total-letters"]').text(report.verbal_no);
        container.find('[data-content-type="print-letters-results-import-sum"]').text(amount);
        container.find('[data-content-type="print-letters-results-total-items"]').text(report.items);

        container.find('[data-content-type="print-letters-results-generated-pdf"]').text(report.generated_pdf_no);

        $("#print-letters-errors-container-wp").css("display", report.errors.length === 0 ? "none" : "block");
        for (const error of report.errors) {
            const error_code_template = this.get_template_jquery("print-letters-errors-ul-code-template");
            const error_desc_template = this.get_template_jquery("print-letters-errors-ul-desc-template");

            error_code_template.find('[data-content-type="code"]').text(error.code);
            error_desc_template.find('[data-content-type="desc"]').text(error.description);

            error_container.append(error_code_template, error_desc_template);
        }

        const close_batches = $("#close-batches").hide().off("click");
        const redo = $("#redo-generate").hide().off("click");

        if (report.errors.length) {
            redo.show().on("click", () => this.client.redo().ew_done(
                x => this.selectNations(null, x.batch_ids, x.customers)
            ));
        } else if (!report.closed) {
            close_batches.show().on("click", () => this.client.close_batch(this.get_def_name()).ew_done(() => {
                this.url_params.set("page", "close_printing_batches");
                window.location.search = this.url_params.toString();
            }));
        }

        let cache_url: string = null;

        const get_pdf_url = (then: (url: string) => void) => {
            if (cache_url === null)
                this.client.download_zip().ew_done(x => then(cache_url = x.url))
            else
                then(cache_url);
        };

        $("#download").off("click").on("click", () => get_pdf_url(url => this.download(url)));
    }

    protected draw_page(reattach: BeginReattach) {
        const state = reattach.effective_state

        if (state.phase === ProgressPhase.SELECT_BATCHES_NATIONS || state.state === ServerProgressStateType.PROCESS_CONCLUDED) {
            if (reattach.concluded && state.state !== ServerProgressStateType.PROCESS_CONCLUDED)
                this.client.get_failed_batches().ew_done(x => this.selectNations(null, x.batch_ids, x.customers))
            else {
                this.client.get_printable_batches().ew_done(x => this.selectBatches(x))
            }
        } else if (state.phase === ProgressPhase.GENERATE_CREDENTIALS)
            this.generateCredentials(reattach);
        else if (state.phase === ProgressPhase.GENERATE_PDF)
            this.generatePDF(reattach);
        else
            this.client.get_report().ew_done(x => this.report(x))
    }

    public override handle() {
        super.handle();
        this.reattach();
        $('#resetAll').on("click", () => this.client.reset_all().ew_done(() => window.location.reload()));
    }

}
