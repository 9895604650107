import $ from "jquery";
import {SmartpriseApiClient} from "../Api/Client";
import {Constants, getDefaultRequestOptions, RetryAfterErrorData} from "../Api/Types";
import {BaseHandler} from "./BaseHandler";
import * as assert from "node:assert";

export class ManageConstantsHandler extends BaseHandler {
    private update_constants(constants: Constants) {
        $('[data-content-type="bank_name"]').text(constants.bank_name);
        $('[data-content-type="owner"]').text(constants.owner);
        $('[data-content-type="bic"]').text(constants.bic);
        $('[data-content-type="iban"]').text(constants.iban);
        $('[data-content-type="enable_online_payments"]').prop("checked", constants.enable_online_payments);
    }

    public override handle() {
        super.handle();
        this.client.get_constants().ew_done(
            x => this.update_constants(x)
        );

        $('[data-action="save"]').on("click", () => {
            $('.constants-wp > div:has(input) > [data-action="edit"]').trigger("click");

            const bank_name = $('[data-content-type="bank_name"]').text();
            const owner = $('[data-content-type="owner"]').text();
            const bic = $('[data-content-type="bic"]').text();
            const iban = $('[data-content-type="iban"]').text();
            const enable_online_payments: boolean = $('[data-content-type="enable_online_payments"]').prop("checked");

            this.client.set_constants(bank_name, owner, bic, iban, enable_online_payments).ew_done(
                x => this.update_constants(x)
            )
        })

        $('[data-action="edit"]').on("click", e => {
            const event_target = $(e.currentTarget);
            const wp_edit_target = event_target.parent().find('[data-role="edit-target"]');
            const edit_target = wp_edit_target[0];

            if (edit_target.tagName === "SPAN") {
                const inp = $(document.createElement("input"));
                inp.prop("type", "text");
                inp.attr("data-role", "edit-target");
                inp.attr("data-content-type", wp_edit_target.attr("data-content-type"));
                inp.val(wp_edit_target.text());

                edit_target.replaceWith(inp[0]);
            } else {
                const span = $(document.createElement("span"));
                span.attr("data-role", "edit-target");
                span.attr("data-content-type", wp_edit_target.attr("data-content-type"));
                span.text(wp_edit_target.val().toString());

                edit_target.replaceWith(span[0]);
            }
        })
    }
}